<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

defineProps<{
  isDark?: boolean
}>()

const me = useProfile()
const { t } = useI18n({ useScope: 'local' })
const localePath = useLocalePath()

const initial = computed(() => {
  if (!me.value)
    return ''

  if (me.value.first_name && me.value.last_name)
    return `${me.value.first_name[0]}${me.value.last_name[0]}`.toLocaleUpperCase()
  else
    return `${me.value.email[0]}${me.value.email[1]}`.toLocaleUpperCase()
})

const role = computed(() => {
  if (!me.value)
    return null

  switch (me.value?.roleId) {
    case 4:
      return 'developer'
    case 3:
    default:
      return 'pro'
  }
})

const { track, logout } = useAnalytic()
const { logout: telemetryLogout } = useTelemetry()

function track_opening(opening: boolean) {
  if (opening)
    track('open-user_menu')
  else
    track('close-user_menu')
}

function track_menu(name: string) {
  track('click-user_menu', { name })
}

function handle_logout() {
  const { selected } = useLibrariesNavigation()
  track('click-user_menu', { name: 'logout' })
  logout()
  telemetryLogout()

  selected.value = null
  useAuthLogout()()
  navigateTo(localePath('index'))
}
</script>

<template>
  <div>
    <NuxtLink
      v-if="!me"
      :to="localePath('auth')"
      :aria-label="t('login')"
      class="flex items-center h-full px-2 transition-colors hover:text-blue-500"
      :class="{
        'text-gray-700': !isDark,
      }"
    >
      <IconNavigationUser class="flex-none w-6 h-6" />
      <span
        class="hidden ml-2 font-semibold leading-none font-display md:inline"
      >{{ t('login') }}</span>
    </NuxtLink>

    <Menu v-else v-slot="{ open }" as="div" class="relative">
      <MenuButton
        :data-x-action="open ? 'close' : 'open'"
        data-x-name="user_menu"
        aria-label="User menu"
        class="flex items-center justify-center h-8 px-4 py-3 transition-colors border-2 rounded-full lg:h-11"
        :class="{
          'bg-violet-400 border-violet-400 text-white hover:bg-blue-500 hover:border-blue-500': isDark,
          'bg-white border-gray-300 text-gray-700 hover:bg-blue-500 hover:border-blue-500 hover:text-white': !isDark,
        }"
        @click="track_opening(!open)"
      >
        <span class="text-sm font-semibold leading-none uppercase font-display">{{ initial }}</span>
        <span v-if="role === 'pro'" class="text-[0.57125rem] leading-[0.7625rem] font-bold px-1.5 py-[0.1875rem] bg-red-500 rounded-full text-white ml-1">PRO</span>

        <IconNavigationMoreOptions class="w-4 h-4 ml-2" />
      </MenuButton>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems class="absolute right-0 grid w-56 grid-cols-1 mt-2 text-gray-700 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div v-if="me?.roleId === 1" class="p-1">
            <MenuItem v-slot="{ active }">
              <NuxtLink
                data-x-name="user_menu_admin"
                to="https://admin.appguide.ca"
                target="_blank"
                class="flex items-center px-4 py-2 text-sm font-semibold rounded-md"
                :class="{
                  'bg-blue-200 text-blue-500': active,
                }"
                @click="track_menu('admin')"
              >
                <IconNavigationUser class="flex-none w-5 h-5 mr-2" />
                Admin
              </NuxtLink>
            </MenuItem>
          </div>

          <div class="p-1">
            <MenuItem v-slot="{ active }">
              <button
                data-x-name="auth_logout"
                type="button"
                class="flex items-center w-full px-4 py-2 text-sm font-semibold rounded-md"
                :class="{
                  'bg-red-50 text-red-500': active,
                }"
                @click="handle_logout"
              >
                <IconNavigationLogout class="flex-none w-5 h-5 mr-2" />
                {{ t('logout') }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "login": "Login",
    "logout": "Logout"
  },
  "fr": {
    "login": "Connexion",
    "logout": "Déconnexion"
  }
}
</i18n>
