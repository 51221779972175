<script setup lang="ts">
import type { RawLocation } from '@intlify/vue-router-bridge'
import type { RouteLocation } from 'vue-router'
import type { ComputedOptions, ConcreteComponent, MethodOptions } from 'vue'

interface Props {
  name: string
  icon?: string
  label: string
  action?: 'open' | 'click' | 'goto' | 'external' | 'mailto'

  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'

  to?: RawLocation | RouteLocation
  external?: boolean
}

interface ComponentElement<T = Record<string, unknown>> {
  is: string | ConcreteComponent<object, any, any, ComputedOptions, MethodOptions>
  props: T
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'click'): void
}>()

const element = computed<ComponentElement>(() => {
  if (props.to)
    return linkElement()

  return buttonElement()
})

function buttonElement(): ComponentElement {
  return {
    is: 'button',
    props: {
      type: props.type || 'button',
      disabled: props.disabled || props.loading,
    },
  }
}

function linkElement(): ComponentElement {
  return {
    is: resolveComponent('NuxtLink'),
    props: {
      to: props.to,
      ...(props.external && {
        target: '_blank',
      }),
    },
  }
}

const open = useState('ui-menu', () => false)
function handleClick() {
  if (props.disabled || props.loading)
    return

  emit('click')
  open.value = false
}
</script>

<template>
  <li class="flex">
    <component :is="element.is" v-bind="element.props" class="flex flex-1 py-2 text-left group" @click="handleClick">
      <i
        v-if="icon"
        class="flex-none mt-0.5 mr-4 text-gray-400 size-5"
        :class="icon"
      />
      <span>{{ label }}</span>

      <i v-if="external" class="i-appguide-external size-3.5 transition-opacity ml-auto pl-2 mt-1.5 text-gray-500 opacity-0 group-hover:opacity-100" />
    </component>
  </li>
</template>
