<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

await fetchUserClaims()

const claims = useClaims()
const activeClaimId = useActiveClaim()

const claim = computed(() => {
  return claims.value.find(claim => claim.id === Number.parseInt(`${activeClaimId.value}`, 10))
  ?? (claims.value.length ? claims.value[0] : null)
})

const open = useState('ui-menu', () => false)
</script>

<template>
  <Button
    data-x-action="goto"
    data-x-name="dev-overview"
    level="2"
    variant="ghost"
    :to="localePath('developer')"
    class="w-full"
    @click="open = false"
  >
    <img
      :src="claim?.summary?.application.icon || '/assets/developer/mock-app-icon.svg'"
      alt="your application icon"
      class="flex-none rounded size-5"
    >
    <span class="flex-none">{{ t('overview') }}</span>
  </Button>
</template>

<i18n lang="json">
{
  "en": {
    "overview": "Overview"
  },
  "fr": {
    "overview": "Vue d'ensemble"
  }
}
</i18n>
