<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.88381 2.50684H4.49815C4.16543 2.52249 2.5 2.76988 2.5 4.63415C2.5 5.49843 2.5 7.58759 2.5 9.02414C2.54697 10.7856 3.7471 14.3084 8.17182 14.3084"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M5.88379 2.50684H4.8739"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M11.4481 2.50684H11.8337C12.1665 2.52249 13.8319 2.76988 13.8319 4.63415C13.8319 5.49843 13.8319 7.58759 13.8319 9.02414C13.7849 10.7856 12.5848 14.3084 8.16007 14.3084V16.7627C8.19921 18.6729 9.33435 22.4932 13.5618 22.4932C17.7892 22.4932 18.9948 18.6729 19.0692 16.7627V12.2102"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M8.16005 14.3084C10.5875 14.3084 12.0445 13.2481 12.8729 12"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M8.16282 14.3084C5.73533 14.3084 4.27839 13.2481 3.44995 12"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M10.4481 2.50684H11.458"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle
      cx="19.0692"
      cy="9.77941"
      r="2.43078"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
