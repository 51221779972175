<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.5 8.54781C6.5 8.94563 6.65803 9.32716 6.93934 9.60846C7.22064 9.88977 7.60217 10.0478 7.99999 10.0478C8.39781 10.0478 8.77934 9.88977 9.06064 9.60846C9.34195 9.32716 9.49998 8.94563 9.49998 8.54781C9.49998 8.14999 9.34195 7.76846 9.06064 7.48716C8.77934 7.20586 8.39781 7.04782 7.99999 7.04782C7.60217 7.04782 7.22064 7.20586 6.93934 7.48716C6.65803 7.76846 6.5 8.14999 6.5 8.54781ZM6.5 4.04784C6.5 4.44566 6.65803 4.82719 6.93934 5.10849C7.22064 5.3898 7.60217 5.54783 7.99999 5.54783C8.39781 5.54783 8.77934 5.3898 9.06064 5.10849C9.34195 4.82719 9.49998 4.44566 9.49998 4.04784C9.49998 3.65002 9.34195 3.26849 9.06064 2.98719C8.77934 2.70589 8.39781 2.54785 7.99999 2.54785C7.60217 2.54785 7.22064 2.70589 6.93934 2.98719C6.65803 3.26849 6.5 3.65002 6.5 4.04784ZM6.5 13.0478C6.5 13.4456 6.65803 13.8271 6.93934 14.1084C7.22064 14.3897 7.60217 14.5478 7.99999 14.5478C8.39781 14.5478 8.77934 14.3897 9.06064 14.1084C9.34195 13.8271 9.49998 13.4456 9.49998 13.0478C9.49998 12.65 9.34195 12.2684 9.06064 11.9871C8.77934 11.7058 8.39781 11.5478 7.99999 11.5478C7.60217 11.5478 7.22064 11.7058 6.93934 11.9871C6.65803 12.2684 6.5 12.65 6.5 13.0478Z" fill="currentColor" />
  </svg>
</template>
