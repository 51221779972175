<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9361 5.07031L4.62228 11.4224C4.06359 11.9845 3.75 12.7448 3.75 13.5373V19C3.75 20.1046 4.64543 21 5.75 21H9.5H14.5H18.25C19.3546 21 20.25 20.1046 20.25 19V13.5373C20.25 12.7448 19.9364 11.9845 19.3777 11.4224L13.0639 5.07031C12.4773 4.48024 11.5227 4.48024 10.9361 5.07031Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 20L15 15C15 13.8954 14.1046 13 13 13L11 13C9.89543 13 9 13.8954 9 15L9 20"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
