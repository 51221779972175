<script setup lang="ts">
defineProps<{
  isDark?: boolean
}>()

const route = useRoute()
const { t, locale } = useI18n()
const localePath = useLocalePath()

const me = useProfile()

const { track } = useAnalytic()
const open_search = useState('global-search-open', () => false)
const open_menu = useMenuModal()
function handleOpenSearch() {
  track('open-search', { source: 'nav-bar' })
  open_menu.value = false
  open_search.value = true
}

const { toLibrary, trackToLibrary, newFeature } = useLibrariesNavigation()

const state = computed(() => {
  if (me.value)
    return 'pro'
  else if (String(route.name).startsWith('dev'))
    return 'dev'
  else
    return ''
})

const role = computed(() => {
  if (!me.value)
    return null

  switch (me.value?.roleId) {
    case 4:
      return 'developer'
    case 3:
    default:
      return 'pro'
  }
})
</script>

<template>
  <div
    data-x-feature="ui_navbar"
    class="flex"
    :class="{
      'bg-white': !isDark,
      'bg-violet-800 text-white': isDark,
    }"
  >
    <UiMenu :is-dark="isDark" />
    <NuxtLink
      data-x-name="home"
      :to="localePath('index')"
      class="ui-nav-logo"
      @click="open_menu = false"
    >
      <img
        :src="isDark ? '/logo/full-white.svg' : '/logo/full.svg'"
        width="162"
        height="32"
        alt="AppGuide"
      >
    </NuxtLink>

    <div
      class="flex items-center justify-end flex-1 pr-2 lg:justify-between md:pr-4 max-lg:gap-1.5"
      :class="{
        'max-lg:text-gray-500 border-gray-200': !isDark,
        'dark': isDark,
      }"
    >
      <div class="items-center gap-1 contents lg:flex">
        <button
          data-x-action="open"
          data-x-name="global_search"
          type="button"
          class="ui-nav-input"
          :class="{
            'hidden 3xs:flex': state !== 'pro',
            'flex': state === 'pro',
          }"
          @click="handleOpenSearch"
        >
          <IconSectionsSearch class="flex-none w-5 h-5" />
          <span>{{ t('menu.navbar.search') }}</span>
        </button>

        <button
          v-if="state !== 'dev'"
          type="button"
          data-x-action="open"
          data-x-name="health_conditions"
          class="ui-nav-action"
          :class="{
            'flex': state === '',
            'hidden sm:flex': state !== '',
          }"
          @click="() => {
            open_menu = false
            handleOpenConditionsModal('navbar')
          }"
        >
          <IconSectionsHealthConditions class="flex-none w-5 h-5" />
          <span>{{ t('menu.navbar.health-conditions') }}</span>
        </button>
      </div>

      <div class="items-center justify-end flex-1 gap-4 contents lg:flex">
        <template v-if="state === 'pro' && role === 'pro'">
          <NuxtLink
            data-x-name="pro_libraries"
            :to="toLibrary"
            class="relative hidden ui-nav-shortcut ui-nav-action-sm xs:flex"
            @click="trackToLibrary('ui-navbar')"
          >
            <IconSectionsLibraries class="flex-none w-5 h-5" />
            <span>{{ t('menu.pro.libraries') }}</span>

            <div v-if="newFeature" aria-hidden="true" class="flex pointer-events-none items-center justify-center top-0 -right-0.5 lg:top-1 lg:right-0 absolute w-2.5 h-2.5">
              <div class="bg-[red] w-full h-full relative z-10 rounded-full" />
              <div class="bg-[red] w-[120%] h-[120%] absolute rounded-full animate-ping" />
            </div>
          </NuxtLink>
        </template>
        <template v-else-if="state === 'dev'">
          <NuxtLink
            data-x-name="dev_claim_trust_report"
            :to="localePath('auth-signup-dev')"
            class="hidden ui-nav-cta-dev md:flex"
          >
            {{ t('menu.navbar.claim-trust-report') }}
          </NuxtLink>
        </template>
        <template v-else-if="role === 'developer'">
          <DevMainNavbarShortcut class="hidden xs:flex ui-nav-action" />
        </template>
        <template v-else>
          <NuxtLink data-x-name="dev_assessment_services" :to="localePath('dev')" class="hidden ui-nav-shortcut xl:flex">
            <IconSectionsDev class="flex-none w-5 h-5" />
            <span>{{ t('menu.navbar.dev') }}</span>
          </NuxtLink>
          <NuxtLink
            data-x-name="health_professionnal"
            :to="locale === 'fr' ? 'https://info846165.typeform.com/to/fG1QuhV8' : 'https://info846165.typeform.com/to/WglAwc3E'"
            target="_blank"
            :aria-label="t('menu.navbar.health-professionnal')"
            class="hidden ui-nav-shortcut xl:flex"
          >
            <IconSectionsHealthcareProviders class="flex-none w-5 h-5" />
            <span>{{ t('menu.navbar.health-professionnal') }}</span>
          </NuxtLink>
        </template>

        <UiUserMenu v-if="me" :is-dark="isDark" class="ml-2" />
        <NuxtLink
          v-else
          data-x-name="auth"
          :to="localePath('auth')"
          class="ml-2 ui-nav-action"
          :class="{
            'hidden 2xs:flex': state !== 'dev',
            'flex': state === 'dev',
          }"
        >
          <IconNavigationUser class="flex-none w-5 h-5" />
          <span>{{ t('menu.navbar.login') }}</span>
        </NuxtLink>
      </div>
    </div>

    <!-- <div class="flex justify-between w-full pl-4 pr-5 md:pl-8">
      <NuxtLink
        :to="localePath('index')"
        class="flex items-center justify-center h-full"
        @click="open_menu = false"
      >
        <img
          :src="isDark ? '/logo/full-white.svg' : '/logo/full.svg'"
          width="162"
          height="32"
          alt="AppGuide"
          class="w-[120px] h-[24px] md:w-[162px] md:h-[32px]"
        >
        <span
          v-if="me"
          class="text-white hidden sm:block ml-2 bg-red-500 font-display font-bold text-[15px] px-2.5 py-1 rounded-full leading-[18px]"
        >PRO</span>
      </NuxtLink>

      <div class="flex justify-between flex-1 text-white">
        <div class="ml-auto xl:my-2.5 xl:w-1/3 xl:ml-8 xl:mr-auto">
          <button
            type="button"
            :aria-label="t('menu.navbar.search')"
            class="flex items-center w-full h-full px-2 transition-colors xl:border-2 xl:px-6 hover:text-blue-500 xl:rounded-full"
            :class="{
              'text-gray-700 border-gray-300 xl:text-gray-500 xl:hover:text-gray-500 hover:border-blue-300': !isDark,
              'xl:hover:text-white xl:bg-violet-600 border-violet-600': isDark,
            }"
            @click="handleOpenSearch"
          >
            <IconSectionsSearch class="flex-none w-5 h-5 sm:w-6 sm:h-6" />
            <span class="hidden ml-2 font-semibold leading-none font-display lg:inline">{{ t('menu.navbar.search') }}</span>
          </button>
        </div>

        <ul class="flex items-center h-full">
          <li class="contents">
            <button
              type="button"
              :aria-label="t('menu.navbar.health-conditions')"
              class="flex items-center h-full px-2 transition-colors hover:text-blue-500"
              :class="{
                'text-gray-700': !isDark,
              }"
              @click="() => {
                open_menu = false
                handleOpenConditionsModal('navbar')
              }"
            >
              <IconSectionsHealthConditions class="flex-none w-5 h-5 sm:w-6 sm:h-6" />
              <span class="hidden ml-2 font-semibold leading-none font-display lg:inline">{{ t('menu.navbar.health-conditions') }}</span>
            </button>
          </li>
          <li v-if="!me" class="contents">
            <NuxtLink
              :to="locale === 'fr' ? 'https://info846165.typeform.com/to/fG1QuhV8' : 'https://info846165.typeform.com/to/WglAwc3E'"
              target="_blank"
              :aria-label="t('menu.navbar.health-professionnal')"
              class="flex items-center h-full px-2 transition-colors hover:text-blue-500"
              :class="{
                'text-gray-700': !isDark,
              }"
            >
              <IconSectionsHealthcareProviders class="flex-none w-5 h-5 sm:w-6 sm:h-6" />
              <span class="hidden ml-2 font-semibold leading-none font-display lg:inline">{{ t('menu.navbar.health-professionnal') }}</span>
            </NuxtLink>
          </li>
          <li v-else class="contents">
            <NuxtLink
              :to="toLibrary"
              class="flex items-center h-full px-2 transition-colors hover:text-blue-500"
              :class="{
                'text-gray-700': !isDark,
              }"
              @click="trackToLibrary('ui-navbar')"
            >
              <IconSectionsLibraries class="flex-none w-5 h-5 sm:w-6 sm:h-6" />
              <span class="hidden ml-1.5 font-semibold leading-none font-display lg:inline">{{ t('menu.pro.libraries') }}</span>

              <span v-if="newFeature" class="relative z-10 flex items-center justify-center w-2.5 h-2.5 ml-1">
                <span class="w-2.5 h-2.5 bg-[red] rounded-full" />
                <span class="animate-ping absolute w-[110%] h-[110%] rounded-full bg-[red] opacity-[0.75]" />
              </span>
            </NuxtLink>
          </li>
          <li class="contents">
            <UiUserMenu :is-dark="isDark" />
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<style scoped lang="scss">
.ui-nav {
  &-logo {
    @apply flex items-center justify-center h-full mx-4;

    @screen lg {
      @apply mx-8;
    }

    img {
      width: 88px;
      height: 18px;

      @screen xs {
        width: 120px;
        height: 24px;
      }

      @screen lg {
        width: 162px;
        height: 32px;
      }
    }
  }

  &-input,
  &-action,
  &-shortcut,
  &-cta,
  &-cta-dev {
    @apply items-center justify-center h-full font-display font-semibold leading-none transition-colors;

    font-size: 15px;

    span, &:deep(span) {
      @apply ml-2;
    }
  }

  &-input,
  &-action,
  &-action-sm {
    @screen md {
      @apply px-3;
    }

    span, &:deep(span) {
      @apply hidden;

      @screen md {
        @apply inline;
      }
    }

    @media (max-width: theme('screens.lg')) {
      @apply min-w-[2.25rem] h-8 rounded-full border-2;

      .dark & {
        @apply border-violet-600;
      }

      &:hover {
        @apply bg-blue-500 text-white border-blue-500;
      }
    }
  }

  &-input,
  &-action {
    @screen lg {
      @apply border-2 h-11 rounded-full;

      .dark & {
        @apply border-violet-600;
      }
    }
  }

  &-input {
    @screen xs {
      min-width: 3.125rem;
    }

    @screen sm {
      @apply px-3 justify-start;

      width: 11.625rem;

      &:hover {
        @apply bg-transparent text-blue-500 border-blue-500;
      }
    }

    @screen lg {
      width: 15.75rem;
    }

    span {
      @apply hidden;

      @screen sm {
        @apply inline;
      }
    }
  }

  &-action {
    &:hover {
      @apply bg-blue-500 text-white border-blue-500;
    }
  }

  &-shortcut {
    @screen xl {
      @apply h-11;

      &:hover {
        @apply text-blue-500;
      }
    }
  }

  &-cta {
    @apply h-8 px-3 bg-blue-500 text-white rounded-full;

    @screen lg {
      @apply h-11 px-4;
    }

    &:hover {
      @apply bg-red-500;
    }
  }

  &-cta-dev {
    @apply h-8 px-3 rounded-full;

    transition: none;

    @screen lg {
      @apply h-11 px-4;
    }

    color: white;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 23.5%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(94deg, #DB326A 0%, #6F25EF 83%);

    &:hover {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 23.5%, rgba(0, 0, 0, 0.20) 100%), #6F25EF;
    }
  }
}
</style>
