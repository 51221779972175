<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'

defineProps<{
  label: string
  to: RouteLocationRaw
  active: boolean
  ctxName: string
}>()

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>

<template>
  <li
    class="relative flex flex-col items-center justify-center transition-colors rounded hover:bg-gray-100 h-18"
    :class="{ active }"
  >
    <slot />

    <NuxtLink
      :to="to"
      :data-x-name="ctxName"
      class="font-extrabold text-[0.625rem] leading-5 tracking-wide uppercase"
      @click="emit('click')"
    >
      {{ label }}
      <span class="absolute inset-0" />
    </NuxtLink>
  </li>
</template>

<style scoped lang="scss">
li {
  & :deep(svg) {
    @apply w-6 h-6 mb-1.5 text-gray-500;
  }

  &.active {
    @apply text-blue-500;

    & :deep(svg) {
      @apply text-blue-500;
    }
  }
}
</style>
