<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.12097 17.8041C7.21939 16.618 9.58955 15.9965 12 16.0001C14.5 16.0001 16.847 16.6551 18.879 17.8041M15 10.0001C15 10.7958 14.6839 11.5588 14.1213 12.1214C13.5587 12.6841 12.7956 13.0001 12 13.0001C11.2043 13.0001 10.4413 12.6841 9.87865 12.1214C9.31604 11.5588 8.99997 10.7958 8.99997 10.0001C8.99997 9.20447 9.31604 8.44141 9.87865 7.8788C10.4413 7.31619 11.2043 7.00012 12 7.00012C12.7956 7.00012 13.5587 7.31619 14.1213 7.8788C14.6839 8.44141 15 9.20447 15 10.0001Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
