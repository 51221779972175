export const useMenuModal = () => useState('ui-menu', () => false)

export function handleToggleMenuModal(isOpen: boolean, source: string) {
  const { track } = useAnalytic()
  const open = useMenuModal()
  open.value = isOpen

  if (isOpen)
    track('open-menu_modal', { source })
  else
    track('close-menu_modal', { source })
}

export function handleCloseMenuModal() {
  const open = useMenuModal()
  open.value = false
}
