<script setup lang="ts">
import { TransitionChild, TransitionRoot } from '@headlessui/vue'

defineProps<{
  isDark?: boolean
}>()

const { t, locale } = useI18n()
const localePath = useLocalePath()

const me = useProfile()
const { toLibrary, trackToLibrary } = useLibrariesNavigation()
const role = computed(() => {
  if (!me.value)
    return null

  switch (me.value?.roleId) {
    case 4:
      return 'developer'
    case 3:
    default:
      return 'pro'
  }
})

const bodyElement = ref<HTMLBodyElement | null>(null)
const isLocked = useScrollLock(bodyElement)
onMounted(() => {
  bodyElement.value = document.querySelector('body')
})

const open = useMenuModal()
watch(open, (isOpen) => {
  isLocked.value = isOpen
})

const { track } = useAnalytic()
const open_search = useState('global-search-open', () => false)
function handleOpenSearch() {
  track('open-search', { source: 'menu' })
  open_search.value = true
}
</script>

<template>
  <button
    id="menu-btn"
    type="button"
    data-x-name="ui-menu"
    :data-x-action="open ? 'close' : 'open'"
    aria-label="Toggle navigation menu"
    :aria-expanded="open ? 'true' : 'false'"
    class="flex flex-col items-center justify-center flex-none h-full w-menu-btn-w"
    :class="{
      'bg-violet-400': isDark,
      'bg-[#424381]': !isDark,
      open,
    }"
    @click="open = !open"
  >
    <span
      v-for="n in 3"
      :id="`menu-btn-line-${n}`"
      :key="n"
      class="w-[26px] h-[2px] lg:w-[32px] bg-white"
    />
  </button>

  <Teleport to="body">
    <TransitionRoot
      id="menu"
      as="aside"
      :show="open"
      data-x-feature="ui_menu"
      class="relative z-menu"
    >
      <TransitionChild
        enter="transition-transform ease-in-out duration-300 transform"
        enter-from="-translate-y-full sm:translate-y-0 sm:-translate-x-full"
        enter-to="translate-y-0 sm:translate-x-0"

        leave="transition-transform ease-out duration-150 transform"
        leave-from="translate-y-0 sm:translate-x-0"
        leave-to="-translate-y-full sm:translate-y-0 sm:-translate-x-full"

        class="fixed bottom-0 w-full max-w-md bg-gray-50 top-menu z-menu shadow-card"
      >
        <div class="h-full px-4 py-10 space-y-8 overflow-y-scroll sm:overflow-y-auto sm:px-12">
          <slot />
        </div>
      </TransitionChild>

      <div class="relative z-10">
        <TransitionChild
          enter="transition-opacity ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"

          leave="transition-opacity ease-out duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            x-click
            data-x-action="close"
            data-x-name="ui-menu-backdrop"
            tabindex="-1"
            class="fixed inset-0 bg-violet-900/80 z-menu-backdrop"
            @click="open = false"
          />
        </TransitionChild>
      </div>
    </TransitionRoot>
  </Teleport>
</template>

<style scoped lang="scss">
.top-menu {
  top: calc(#{theme('spacing.navbar')} + var(--extra-top, 0px))
}

#menu-btn {
  --space-y: 0.5rem;
  --translate-y: calc(var(--space-y) + 2px);

  & > :not([hidden]) ~ :not([hidden]) {
    margin-top: var(--space-y);
  }

  & > span {
    @apply transition-all ease-linear;
  }

  #menu-btn-line-2 {
    @apply origin-center;
  }

  &.open {
    #menu-btn-line-1 {
      transform: translateY(var(--translate-y)) rotate(45deg);
      transition-delay: 150ms;
    }
    #menu-btn-line-2 {
      transform: scale(0, 1);
      transition-duration: 150ms;
    }
    #menu-btn-line-3 {
      transform: translateY(calc(var(--translate-y) * -1)) rotate(-45deg);
      transition-delay: 150ms;
    }
  }
}

body.scrolled {
  #menu-btn {
    --space-y: 0.375rem;

    @screen md {
      --space-y: 0.5rem;
    }
  }
}
</style>
