<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 4.25C3 3.65326 3.23705 3.08097 3.65901 2.65901C4.08097 2.23705 4.65326 2 5.25 2H10.75C11.3467 2 11.919 2.23705 12.341 2.65901C12.7629 3.08097 13 3.65326 13 4.25V6.25C13 6.44891 12.921 6.63968 12.7803 6.78033C12.6397 6.92098 12.4489 7 12.25 7C12.0511 7 11.8603 6.92098 11.7197 6.78033C11.579 6.63968 11.5 6.44891 11.5 6.25V4.25C11.5 4.05109 11.421 3.86032 11.2803 3.71967C11.1397 3.57902 10.9489 3.5 10.75 3.5H5.25C5.05109 3.5 4.86032 3.57902 4.71967 3.71967C4.57902 3.86032 4.5 4.05109 4.5 4.25V15.75C4.5 16.164 4.836 16.5 5.25 16.5H10.75C10.9489 16.5 11.1397 16.421 11.2803 16.2803C11.421 16.1397 11.5 15.9489 11.5 15.75V13.75C11.5 13.5511 11.579 13.3603 11.7197 13.2197C11.8603 13.079 12.0511 13 12.25 13C12.4489 13 12.6397 13.079 12.7803 13.2197C12.921 13.3603 13 13.5511 13 13.75V15.75C13 16.3467 12.7629 16.919 12.341 17.341C11.919 17.7629 11.3467 18 10.75 18H5.25C4.65326 18 4.08097 17.7629 3.65901 17.341C3.23705 16.919 3 16.3467 3 15.75V4.25Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 10C19 9.80111 18.921 9.61035 18.7803 9.46969C18.6397 9.32904 18.4489 9.25002 18.25 9.25002H8.704L9.752 8.30702C9.82753 8.24177 9.88927 8.16209 9.93361 8.07267C9.97795 7.98324 10.004 7.88587 10.0102 7.78625C10.0164 7.68663 10.0027 7.58677 9.96984 7.49252C9.93697 7.39827 9.88562 7.31154 9.8188 7.23739C9.75197 7.16325 9.67102 7.10319 9.58069 7.06073C9.49035 7.01827 9.39245 6.99428 9.29272 6.99014C9.19299 6.98601 9.09344 7.00183 8.99991 7.03667C8.90637 7.0715 8.82073 7.12466 8.748 7.19302L6.248 9.44302C6.17001 9.51335 6.10765 9.59927 6.06498 9.69522C6.0223 9.79117 6.00024 9.89501 6.00024 10C6.00024 10.105 6.0223 10.2089 6.06498 10.3048C6.10765 10.4008 6.17001 10.4867 6.248 10.557L8.748 12.807C8.82073 12.8754 8.90637 12.9285 8.99991 12.9634C9.09344 12.9982 9.19299 13.014 9.29272 13.0099C9.39245 13.0058 9.49035 12.9818 9.58069 12.9393C9.67102 12.8969 9.75197 12.8368 9.8188 12.7627C9.88562 12.6885 9.93697 12.6018 9.96984 12.5075C10.0027 12.4133 10.0164 12.3134 10.0102 12.2138C10.004 12.1142 9.97795 12.0168 9.93361 11.9274C9.88927 11.838 9.82753 11.7583 9.752 11.693L8.704 10.75H18.25C18.3485 10.75 18.446 10.7306 18.537 10.6929C18.628 10.6552 18.7107 10.6 18.7803 10.5304C18.85 10.4607 18.9052 10.378 18.9429 10.287C18.9806 10.196 19 10.0985 19 10Z"
      fill="currentColor"
    />
  </svg>
</template>