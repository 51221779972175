<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 1.5H6.25C5.65326 1.5 5.08097 1.73705 4.65901 2.15901C4.23705 2.58097 4 3.15326 4 3.75V20.25C4 20.8467 4.23705 21.419 4.65901 21.841C5.08097 22.2629 5.65326 22.5 6.25 22.5H13.75C14.3467 22.5 14.919 22.2629 15.341 21.841C15.7629 21.419 16 20.8467 16 20.25V19M8.5 1.5V3H11.5V1.5M8.5 1.5H11.5M11.5 1.5H13.75C14.3467 1.5 14.919 1.73705 15.341 2.15901C15.7629 2.58097 16 3.15326 16 3.75V4.5M8.5 20.25H11.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.5 9.45455L22 12L19.5 14.5455M12.5 14.5455L10 12L12.5 9.45455M17.0714 8L14.9286 16"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
