<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

await fetchUserClaims()

const claims = useClaims()
const activeClaimId = useActiveClaim()

const claim = computed(() => {
  return claims.value.find(claim => claim.id === Number.parseInt(`${activeClaimId.value}`, 10))
  ?? (claims.value.length ? claims.value[0] : null)
})
</script>

<template>
  <NuxtLink :to="localePath('developer')">
    <img
      :src="claim?.summary?.application.icon || '/assets/developer/mock-app-icon.svg'"
      alt="your application icon"
      class="flex-none w-4 h-4 rounded lg:w-5 lg:h-5"
    >
    <span class="flex-none">{{ t('overview') }}</span>
  </NuxtLink>
</template>

<i18n lang="json">
{
  "en": {
    "overview": "Overview"
  },
  "fr": {
    "overview": "Vue d'ensemble"
  }
}
</i18n>
