<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()
const route = useRoute()
const routeName = computed(() => `${route.name as string}`.split('___')[0])

const { toLibrary, trackToLibrary, newFeature } = useLibrariesNavigation()
</script>

<template>
  <nav data-x-feature="ui_sidebar" class="px-1 pt-6 bg-white shadow-card">
    <ul class="flex flex-col gap-y-2">
      <UiSidebarLink
        :label="t('menu.pro.welcome')"
        :to="localePath('pro')"
        :active="routeName === 'pro'"
        ctx-name="pro_welcome"
      >
        <IconSectionsHome />
      </UiSidebarLink>

      <UiSidebarLink
        :label="t('menu.pro.libraries')"
        :to="toLibrary"
        :active="routeName.startsWith('pro-libraries')"
        class="ph-no-capture"
        ctx-name="pro_libraries"
        @click="trackToLibrary('ui-sidebar')"
      >
        <IconSectionsLibraries />

        <span v-if="newFeature" class="absolute z-10 top-2 right-2 flex items-center justify-center w-2.5 h-2.5 ml-1">
          <span class="w-2.5 h-2.5 bg-[red] rounded-full" />
          <span class="animate-ping absolute w-[110%] h-[110%] rounded-full bg-[red] opacity-[0.75]" />
        </span>
      </UiSidebarLink>
    </ul>
  </nav>
</template>
