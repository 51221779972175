<script setup lang="ts">
defineProps<{
  isDark?: boolean
}>()

const { t, locale } = useI18n()
const localePath = useLocalePath()

const open = useState('ui-menu', () => false)

const profile = useProfile()
const role = computed(() => {
  if (!profile.value)
    return null

  switch (profile.value?.roleId) {
    case 4:
      return 'developer'
    case 3:
    default:
      return 'pro'
  }
})

const { track } = useAnalytic()
const open_search = useState('global-search-open', () => false)
function handleOpenSearch() {
  track('open-search', { source: 'menu' })
  open_search.value = true
}

const claims = useClaims()
const { toLibrary, trackToLibrary } = useLibrariesNavigation()
</script>

<template>
  <UiMenuBackbone :is-dark="isDark">
    <div v-if="role === 'developer'">
      <DevMainMenuShortcut />
    </div>
    <div v-else-if="role === 'pro'">
      <Button
        level="2"
        variant="ghost"
        :to="toLibrary"
        class="w-full group"
        @click="() => {
          trackToLibrary('ui-menu')
          open = false
        }"
      >
        <i class="text-gray-400 transition-colors i-appguide-collections size-5 group-hover:text-blue-500" />
        {{ t('menu.pro.app-collections') }}
      </Button>
    </div>
    <!-- <div v-else class="space-y-4">
      <Button
        :to="localePath('auth')"
        level="2"
        class="w-full"
        @click="open = false"
      >
        <i class="i-appguide-user-circle size-5" />
        {{ t('menu.navbar.login') }}
      </Button>
      <Button
        :to="localePath('auth')"
        level="2"
        variant="ghost"
        class="w-full"
        @click="open = false"
      >
        {{ t('menu.navbar.signup') }}
      </Button>
    </div> -->

    <UiMenuGroup :label="t('menu.navbar.title')">
      <UiMenuItem
        action="open"
        name="global_search"
        icon="i-appguide-search"
        :label="t('menu.navbar.search')"
        @click="handleOpenSearch"
      />
      <UiMenuItem
        action="open"
        name="health_conditions"
        icon="i-appguide-health"
        :label="t('menu.navbar.health-conditions')"
        @click="handleOpenConditionsModal('menu')"
      />
      <UiMenuItem
        name="articles"
        icon="i-appguide-open-book"
        :label="t('menu.resources.articles')"
        :to="localePath('resources-articles')"
      />
    </UiMenuGroup>

    <UiMenuGroup v-if="role === 'developer'" :label="t('menu.dev.title')">
      <UiMenuItem
        name="dev_services"
        icon="i-appguide-phone-module"
        :label="t('menu.dev.services')"
        :to="localePath('dev')"
      />
      <UiMenuItem
        name="dev_service_pre-clinical"
        icon="i-appguide-heart-check"
        :label="t('menu.dev.pre-clinical')"
        :to="localePath('dev-pre-clinical-assessment')"
      />
      <UiMenuItem
        name="dev_service_iso-82304-2"
        icon="i-appguide-module-iso"
        :label="t('menu.dev.iso-82304-2')"
        :to="localePath('dev-iso-82304-2-assessment')"
      />
      <UiMenuItem
        name="dev_claim-app"
        icon="i-appguide-holding-tablet"
        :label="t('menu.dev.claim-second-app')"
        :to="claims.length ? localePath('developer-claim') : localePath('developer-first-claim')"
      />
    </UiMenuGroup>

    <UiMenuGroup v-else-if="role === 'pro'" :label="t('menu.pro.title')">
      <UiMenuItem
        name="pro_welcome"
        icon="i-appguide-home"
        :label="t('menu.pro.welcome')"
        :to="localePath('pro')"
      />
      <UiMenuItem
        name="pro_libraries"
        icon="i-appguide-collections"
        :label="t('menu.pro.app-collections')"
        :to="toLibrary"
        @click="trackToLibrary('ui-menu')"
      />
      <UiMenuItem
        name="pro_libraries_get-library"
        icon="i-appguide-collection-add"
        :label="t('menu.pro.get-library')"
        :to="localePath('pro-libraries-get-library')"
      />
    </UiMenuGroup>

    <template v-else>
      <UiMenuGroup :label="t('menu.pro.title')">
        <UiMenuItem
          name="health_professionnal"
          icon="i-appguide-pro"
          :label="t('menu.pro.free-access')"
          :to="t('menu.pro.free-access-url')"
          external
        />
      </UiMenuGroup>

      <UiMenuGroup :label="t('menu.dev.title')">
        <UiMenuItem
          name="dev_services"
          icon="i-appguide-phone-module"
          :label="t('menu.dev.services')"
          :to="localePath('dev')"
        />
        <UiMenuItem
          name="dev_claim-app"
          icon="i-appguide-holding-tablet"
          :label="t('menu.dev.claim-first-app')"
          :to="localePath('auth-signup-dev')"
        />
      </UiMenuGroup>

      <UiMenuGroup :label="t('menu.invest.title')">
        <UiMenuItem
          name="investors-questions-to-de-risk-your-next-digital-health-investment"
          icon="i-appguide-shield-check"
          :label="t('menu.invest.derisk-investment')"
          :to="localePath('investors-questions-to-de-risk-your-next-digital-health-investment')"
        />
      </UiMenuGroup>
    </template>

    <UiMenuGroup :label="t('menu.about.title')" sm>
      <UiMenuItem
        name="about_quality_scores"
        :label="t('menu.about.quality-scores')"
        :to="localePath('quality-scores')"
      />
      <UiMenuItem
        name="about_relevance"
        :label="t('menu.about.relevance')"
        :to="localePath('relevance')"
      />
      <UiMenuItem
        name="about_us"
        :label="t('menu.about.about')"
        :to="localePath('about')"
      />
      <UiMenuItem
        name="career"
        :label="t('menu.about.career')"
        :to="locale === 'fr' ? '/fr/resources/articles/carriere' : '/resources/articles/career'"
      />
      <UiMenuItem
        action="mailto"
        name="about_contact_us"
        :label="t('menu.about.contact-us')"
        to="mailto:support@therappx.com"
      />
    </UiMenuGroup>

    <UtilsLangSwitcher
      direction="top-right"
      @change="open = false"
    />
  </UiMenuBackbone>
</template>
